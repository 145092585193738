.WelcomeGallery {
	height: calc(100vh - 54px);
}
.WelcomeOneBlock {
	height: 100%;
}

.WelcomeTextBlock {
	padding-top: 54px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.WelcomeTitle {
	font-size: 22px;
	text-align: center;
}

.WelcomeDescription {
	font-size: 16px;
	color: var(--content_placeholder_text);
}

.WelcomeImg {
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.WelcomeImg img {
	max-width: 55vw;
}