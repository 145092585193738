.ScrollTopButtonContainer {
	position: fixed;
	bottom: 70px;
	right: 35px;
	z-index:99999;
}

.ScrollTopButtonIcon24Back {
    -moz-transform: rotate(90deg); /* Для Firefox */
    -ms-transform: rotate(90deg); /* Для IE */
    -webkit-transform: rotate(90deg); /* Для Safari, Chrome, iOS */
    -o-transform: rotate(90deg); /* Для Opera */
    transform: rotate(90deg);
}

.ScrollTopButtonIcon24Back:hover {
	cursor:pointer;
}