.NotifyBlock {
	border-radius: 3px;
	border-width: 1px;
	border-style: solid;
	padding: 10px 15px;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 18px;
	color: var(--field_text_placeholder);
}


.info {
	background-color: var(--background_light); 
	border-color: var(--icon_secondary);
}

.error {
	background-color: #FFEFE9;
	border-color: #F2AB99;
}

.success {
	background-color: #EFFAED;
	border-color: #AFD4A9;
}