.AllThumbnails {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	flex-wrap: wrap;
}

.AllThumbnailsMobile {
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	-webkit-overflow-scrolling: touch !important;
	white-space: nowrap;
	overflow-x: scroll;
	overflow-y: hidden;
}
.AllThumbnailsMobile::-webkit-scrollbar {
	display: none;
}

.AllThumbnailsMobile::-webkit-scrollbar-button {
	display: none;
}


.ThumbnailBox {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ThumbnailBoxImg {
	object-fit: cover;
	width: 185px;
	height: 127px;
	padding: 3.7px;
}
.ThumbnailBoxImg:hover {
	cursor: pointer;
}

.ThumbnailBoxImgMobile {
	object-fit: cover;
	width: 100px;
	height: 70px;
	margin: 3px;
}

.showBoxImg {
	position: absolute;
}
.showBoxImg img {
	max-width: 100vw;
	max-height: 80vh;
}

.showBoxIcon28ChevronBack {
	position: absolute;
	z-index: 999;
	left: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
    height: 24px;
}
.showBoxIcon28ChevronForward {
	position: absolute;
	z-index: 999;
	right: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
    height: 24px;
}

.navSvg {
	width: 24px;
	height: 24px;
	background-color: rgba(0, 0, 0, .1);
	border-radius: 100%;
}
.navSvg:hover {
	cursor: pointer;
}

.showBoxIcon24Dismiss {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
}
.showBoxIcon24Dismiss:hover {
	cursor: pointer;
}

/* fix VKUI*/
.PopoutWrapper {
	z-index: 9999;
}