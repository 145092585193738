.MenuIcon {
	-moz-transform: rotate(90deg); /* Для Firefox */
	-ms-transform: rotate(90deg); /* Для IE */
	-webkit-transform: rotate(90deg); /* Для Safari, Chrome, iOS */
	-o-transform: rotate(90deg); /* Для Opera */
	transform: rotate(90deg);
	color: var(--content_placeholder_icon);
}

.MenuIconOpen {
	-moz-transform: rotate(-90deg); /* Для Firefox */
	-ms-transform: rotate(-90deg); /* Для IE */
	-webkit-transform: rotate(-90deg); /* Для Safari, Chrome, iOS */
	-o-transform: rotate(-90deg); /* Для Opera */
	transform: rotate(-90deg);
	color: var(--content_placeholder_icon);
}

.MenuIcon:hover, .MenuIconOpen:hover{
	cursor: pointer;
}
