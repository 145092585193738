.SearchBlockIcon24Browser_close {
	-moz-transform: rotate(90deg); /* Для Firefox */
	-ms-transform: rotate(90deg); /* Для IE */
	-webkit-transform: rotate(90deg); /* Для Safari, Chrome, iOS */
	-o-transform: rotate(90deg); /* Для Opera */
	transform: rotate(90deg);
}
.SearchBlockIcon24Browser_open {
	-moz-transform: rotate(-90deg); /* Для Firefox */
	-ms-transform: rotate(-90deg); /* Для IE */
	-webkit-transform: rotate(-90deg); /* Для Safari, Chrome, iOS */
	-o-transform: rotate(-90deg); /* Для Opera */
	transform: rotate(-90deg);
}