.DesktopHeader {
	width: 100vw;
	height: 50px;
	color: var(--attach_picker_tab_active_text);
}

.DesktopHeader > .leftButton:hover {
	cursor: pointer;
}
.ScrollTopButtonContainer {
	position: fixed;
	bottom: 70px;
	right: 35px;
	z-index:99999;
}

.ScrollTopButtonIcon24Back { /* Для Firefox */ /* Для IE */
    -webkit-transform: rotate(90deg); /* Для Safari, Chrome, iOS */ /* Для Opera */
    transform: rotate(90deg);
}

.ScrollTopButtonIcon24Back:hover {
	cursor:pointer;
}
.online .Avatar__in:after {
	z-index: 9999;
	bottom: 1%;
	right: 1%;
	border: 2px solid var(--background_content);
	height: 9px;
	width: 9px;
	content: "";
	position: absolute;
	background-color: #8ac176;
	border-radius: 50%;
}

.online_mobile .Avatar__in:after {
	z-index: 99999;
	bottom: 3%;
	right: 3%;
	height: 15px;
	width: 9px;
	background-color: transparent;
	background-image: url(/static/media/mobile.024259c8.svg);
	border: 0px;
	border-radius: 2px;
	background-repeat: no-repeat;
}

.BlockName {
	display: flex;
	vertical-align: middle;
	align-items: center;
}

.VerifiedIcon {
	color: var(--icon_name);
	margin-left: 4px;
}
.TextBlockShowAll {
	margin-top: 6px;
}
.TextBlockShowAll_item:hover {
	cursor: pointer;
}
.AllThumbnails {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	flex-wrap: wrap;
}

.AllThumbnailsMobile {
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	-webkit-overflow-scrolling: touch !important;
	white-space: nowrap;
	overflow-x: scroll;
	overflow-y: hidden;
}
.AllThumbnailsMobile::-webkit-scrollbar {
	display: none;
}

.AllThumbnailsMobile::-webkit-scrollbar-button {
	display: none;
}


.ThumbnailBox {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ThumbnailBoxImg {
	object-fit: cover;
	width: 185px;
	height: 127px;
	padding: 3.7px;
}
.ThumbnailBoxImg:hover {
	cursor: pointer;
}

.ThumbnailBoxImgMobile {
	object-fit: cover;
	width: 100px;
	height: 70px;
	margin: 3px;
}

.showBoxImg {
	position: absolute;
}
.showBoxImg img {
	max-width: 100vw;
	max-height: 80vh;
}

.showBoxIcon28ChevronBack {
	position: absolute;
	z-index: 999;
	left: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
    height: 24px;
}
.showBoxIcon28ChevronForward {
	position: absolute;
	z-index: 999;
	right: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
    height: 24px;
}

.navSvg {
	width: 24px;
	height: 24px;
	background-color: rgba(0, 0, 0, .1);
	border-radius: 100%;
}
.navSvg:hover {
	cursor: pointer;
}

.showBoxIcon24Dismiss {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
}
.showBoxIcon24Dismiss:hover {
	cursor: pointer;
}

/* fix VKUI*/
.PopoutWrapper {
	z-index: 9999;
}
.NotifyBlock {
	border-radius: 3px;
	border-width: 1px;
	border-style: solid;
	padding: 10px 15px;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 18px;
	color: var(--field_text_placeholder);
}


.info {
	background-color: var(--background_light); 
	border-color: var(--icon_secondary);
}

.error {
	background-color: #FFEFE9;
	border-color: #F2AB99;
}

.success {
	background-color: #EFFAED;
	border-color: #AFD4A9;
}
.MenuIcon { /* Для Firefox */ /* Для IE */
	-webkit-transform: rotate(90deg); /* Для Safari, Chrome, iOS */ /* Для Opera */
	transform: rotate(90deg);
	color: var(--content_placeholder_icon);
}

.MenuIconOpen { /* Для Firefox */ /* Для IE */
	-webkit-transform: rotate(-90deg); /* Для Safari, Chrome, iOS */ /* Для Opera */
	transform: rotate(-90deg);
	color: var(--content_placeholder_icon);
}

.MenuIcon:hover, .MenuIconOpen:hover{
	cursor: pointer;
}

.SearchBlockIcon24Browser_close { /* Для Firefox */ /* Для IE */
	-webkit-transform: rotate(90deg); /* Для Safari, Chrome, iOS */ /* Для Opera */
	transform: rotate(90deg);
}
.SearchBlockIcon24Browser_open { /* Для Firefox */ /* Для IE */
	-webkit-transform: rotate(-90deg); /* Для Safari, Chrome, iOS */ /* Для Opera */
	transform: rotate(-90deg);
}
.WelcomeGallery {
	height: calc(100vh - 54px);
}
.WelcomeOneBlock {
	height: 100%;
}

.WelcomeTextBlock {
	padding-top: 54px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.WelcomeTitle {
	font-size: 22px;
	text-align: center;
}

.WelcomeDescription {
	font-size: 16px;
	color: var(--content_placeholder_text);
}

.WelcomeImg {
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.WelcomeImg img {
	max-width: 55vw;
}

.badVersion {
	padding-top: 30vh;
	box-sizing: border-box;
	text-align: center;
	width: 100vw;
}

.badVersionItem {
	width: 100vw;
}
:root {
	--evaluation_favorite_star: #e8d306;
	--wait_dots: #6287AE;
}

body[scheme="client_dark"] {
	color: #fff;
}

body[scheme="space_gray"] {
	color: #fff;
}

body::-webkit-scrollbar-track {
	background-color: var(--separator_common);
}

body::-webkit-scrollbar {
	width: 7px;
	background-color: #fff;
}

body::-webkit-scrollbar-thumb {
	background-color: var(--attach_picker_tab_inactive_text);
	border-radius: 6px;
}

.flex_start {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flex_end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex_center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex_arrow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pb-0 {
	padding-bottom: 0px;
}
.pt-0 {
	padding-top: 0px;
}
.pt-5 {
	padding-top: 5px;
}
.pb-5 {
	padding-bottom: 5px;
}
.pr-5 {
	padding-right: 5px;
}
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.mr-5 {
	margin-right: 5px;
}
.ml-5 {
	margin-left: 5px;
}
.mt-5 {
	margin-top: 5px;
}

.imgBig {
	max-width: 15vw;
	filter: alpha(Opacity=50);
	opacity: 0.5; 
}

